<template>
  <div>
    <b-container>
      <b-card-group deck>
        <b-card style="top:20px" class="shadow rounded">
          <b-row>
            <!--          过滤器-->
            <b-col lg="12" class="my-1">
              <b-form-group
                  label="按条件过滤"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                      class="w-50"
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="请输入"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!--过滤器选择-->
          </b-row>
          <!--  展示的部门数据        -->
          <b-table
              :items="items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="sm"
              show-empty
              small
              @filtered="onFiltered">
            <template #cell(selections)="row">
              <b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">
                修改信息
              </b-button>
              <b-button size="sm" @click="row.toggleDetails">
                {{ row.detailsShowing ? '收起' : '展开' }}
              </b-button>
            </template>
            <template #cell(action)="row">
              <b-icon icon="x-circle" variant="secondary" class="m-1"
                      @click="removeOrganizationById(row.item.id)"></b-icon>
            </template>
            <!--      编辑信息      -->
            <template #row-details="row">
              <b-card>
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>部门编号:</b></b-col>
                  <b-col>{{ row.item.id }}</b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>部门名称:</b></b-col>
                  <b-col>{{ row.item.name }}</b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>主管编号:</b></b-col>
                  <b-col>{{ row.item.ownerId }}</b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>类型:</b></b-col>
                  <b-col>{{ row.item.type }}</b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>上级归属编号:</b></b-col>
                  <b-col>{{ row.item.superiorId }}</b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>地区编号:</b></b-col>
                  <b-col>{{ row.item.areaId }}</b-col>
                </b-row>
              </b-card>
            </template>
          </b-table>
          <!--    分页选择-->
          <b-row>
            <b-col sm="5" md="6" class="my-1">
              <b-form-group
                  label="Per page"
                  label-for="per-page-select"
                  label-cols-sm="6"
                  label-cols-md="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <!--分页-->
            <b-col sm="7" md="4" class="my-1">
              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
              ></b-pagination>
            </b-col>
            <!--    新建组织        -->
            <b-col>
              <b-button variant="outline-primary" style="width: 60%;margin-top: 0.2rem;"
                        @click="toCreateOrganization">新建
              </b-button>
            </b-col>
          </b-row>
          <!--     编辑信息         -->
          <!-- Info modal -->
          <b-modal :id="infoModal.id" :title="infoModal.title" @hide="resetInfoModal">
            <form class="row g-3">
              <div class="col-md-6">
                <label class="form-label">部门编号:</label>
                <input type="name" class="form-control" id="inputDeptId" placeholder="Input DeptId">
              </div>
              <div class="col-md-6">
                <label class="form-label">部门名称:</label>
                <input type="level" class="form-control" id="inputname" placeholder="Input name">
              </div>
              <div class="col-md-6">
                <label class="form-label">主管编号:</label>
                <input type="text" class="form-control" id="inputOwnerId" placeholder="Input OwnerId">
              </div>
              <div class="col-md-6">
                <label class="form-label">上级归属编号:</label>
                <input type="text" class="form-control" id="inputSuperiorId" placeholder="Input SuperiorId">
              </div>
              <div class="col-md-6">
                <label class="form-label">地区编号:</label>
                <input type="text" class="form-control" id="inputAreaId" placeholder="Input AreaId">
              </div>
              <div class="col-md-6">
                <label class="form-label">类型:</label>
                <select id="inputState" class="form-select">
                  <option selected>Choose...</option>
                  <option>A</option>
                  <option>B</option>
                  <option>C</option>
                  <option>D</option>
                </select>
              </div>
              <div class="col-12">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="gridCheck">
                  <label class="form-check-label">
                    Check me out
                  </label>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" @click="modifyOrganization">ModifyOrganization</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </form>
          </b-modal>
        </b-card>
      </b-card-group>
    </b-container>
  </div>
</template>
<script>
import {
  changeOrganizationName,
  changeOwnerId,
  changeOrganizationId,
  changeAreaId,
  changeSuperiorId,
  changeType,
  removeAreaById,
  removeOrganizationById,
  showMyOrganizations,
  createOrganization,
  showOrganizationsByType
} from "../../api";

export default {
  data() {
    return {
      items: [],
      fields: [
        {key: 'id', label: '组织编号', sortable: true, sortDirection: 'asc'},
        {key: 'name', label: '组织名', class: 'text-center'},
        {key: 'ownerId', label: '所有者', sortable: true, class: 'text-center'},
        {key: 'type', label: '类型', class: 'text-center'},
        {key: 'selections', label: '操作', class: 'text-center'},
        {key: 'action', label: '删除', class: 'text-center'}
      ],
      totalRows: 100,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, {value: 100, text: "Show a lot"}],
      sortBy: '',
      sortDesc: false,
      showDismissibleAlert: false,
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      }
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key}
          })
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    //获得所有组织、
    this.getAllOragnization()
  },
  methods: {
    /*修改组织信息*/
    modifyOrganization() {
      changeOrganizationName()
    },
    /*根据类型查询组织*/
    queryOragnization() {
      showOrganizationsByType(this.type).then((res) => {
        if (res.data.status) {
          this.getAllOragnization()
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    /*跳转到添加组织的页面*/
    toCreateOrganization() {

    },
    /*添加组织*/
    createOrganization() {
      createOrganization(this.name, this.areaId, this.superiorId, this.type).then((res) => {
        if (res.data.status) {

          this.getAllOragnization()
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    /*获得所有组织*/
    getAllOragnization() {
      showMyOrganizations(1, 100).then((res) => {
        if (res.data.status) {
          this.items = res.data.data.content
        } else {
          this.$message.error(res.data.message)
        }
      })
    },

    info(item, index, button) {
      this.infoModal.title = `部门信息:`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    /*删除组织根据Id*/
    removeOrganizationById(id) {
      removeOrganizationById(id).then((res) => {
        if (res.data.status) {
          this.$message.success(res.data.message)
          this.getAllOragnization()
        } else {
          this.$message.error(res.data.message)
        }
      })

    }
  },

}
</script>
<style scoped>
.mb-0 {
  display: flex;
}

.my-1 {

}

.my-0 {

}

.mt-1 {

}

.mr-1 {
  margin-right: 10px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.option_1 {
  padding-right: 0.4rem;
}
</style>
